@tailwind components;

@layer components { 
  footer {
    @apply font-manrope dark:text-white border-t dark:border-white;
  }

  footer ul {
    @apply flex gap-4;
  }

  footer ul li a {
    @apply text-base capitalize font-light underline transition duration-300 hover:text-kollectiff-lime flex;
  }

  footer h3 {
    @apply text-xl font-manrope;
  }

  footer label {
    @apply text-xl;
  }

  footer input {
    @apply dark:border dark:border-white dark:bg-black rounded-[4px] p-3 text-base placeholder:text-[#828282] w-full pr-24 disabled:text-gray-500 transition duration-300;
  }

  footer button[type=submit] {
    @apply absolute text-white uppercase top-1/2 right-4 -translate-y-1/2 font-monument-grotesk-mono transition duration-500 z-[10];
  }
}

footer .social-links a:focus-visible {
  outline: 2px solid #BBFF00;
  border-radius: 2px;
  z-index: 10;
  color: #BBFF00;
  position: relative;
}