@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MonumentGroteskMono';
  src: url('assets/fonts/MonumentGroteskMono-Regular-Trial.otf') format('opentype');
  font-weight: 'normal';
  font-style: 'normal';
}

@font-face {
  font-family: 'MonumentGroteskMono';
  src: url('assets/fonts/MonumentGroteskMono-Light-Trial.otf') format('opentype');
  font-weight: 'light';
  font-style: 'normal';
}

@layer base {
  html, body, #root {
    @apply h-full scroll-smooth;
    scrollbar-color: #BBFF00 black;
    scrollbar-width: thin;
  }

  body {
    @apply dark:bg-black overflow-x-hidden font-manrope;
  }

  header {
    @apply flex flex-col;
  }

  h1 {
    @apply text-[40px] lg:text-[80px] font-light dark:text-white font-manrope lg:leading-[96px];
  }

  h2 {
    @apply font-manrope font-bold text-3xl lg:text-[40px] lg:leading-[48px] dark:text-white leading-snug;
  }

  *:focus {
    outline: none;
  }

  *:focus-visible {
    @apply outline outline-[2px] outline-kollectiff-lime rounded-[2px] z-[10] text-kollectiff-lime relative;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus-visible,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus-visible,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus-visible,
  select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }  
}

@layer components {
  .hero {
    @apply h-full grow bg-cover bg-right flex items-end;
  }
  
  .hero img {
    @apply w-full relative;
  }

  .video-square {
    @apply size-auto aspect-square relative;
  }

  .video-square video {
    @apply w-full h-full absolute object-cover z-0;
  }

  .read-more {
    --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
    @apply relative font-monument-grotesk-mono text-base lg:text-xl font-light uppercase dark:text-white;
  }

  .read-more::before, 
  .read-more::after {
    @apply bg-kollectiff-lime -bottom-1.5 left-0 absolute block w-full h-1;
    content: "";
    transition: 0.6s var(--ease-out-expo);
  }

  .read-more::before {
    transform: scaleX(0);
    transform-origin: left;
  }

  .read-more::after {
    transform-origin: right;
    transition-delay: 0.25s;
  }

  .read-more:hover::before {
    transform: scaleX(1);
    transition-delay: 0.25s;
  }

  .read-more:hover::after {
    transform: scaleX(0);
    transition-delay: 0s;
  }

  .experiences {
    @apply w-full h-[700px] overflow-hidden flex items-center relative;
  }

  .experiences h2 {
    @apply text-[32px] lg:text-[64px] font-light text-center;
  }

  .experiences button {
    @apply w-auto bg-kollectiff-lime max-w-fit text-lg lg:text-xl px-4 lg:px-8 py-4 rounded-full text-black;
  }

  .experiences img {
    scale: 1.6;
  }

  .capability {
    @apply flex flex-col gap-4 dark:text-white font-manrope;
  }

  .capability img {
    @apply w-full object-cover;
  }

  .capability h3 {
    @apply text-xl font-semibold;
  }

  .capability {
    position: relative;
    overflow: hidden;
  }
  .capability-asset {
    width: 100%;
    height: auto;
    position: relative;
  }
  .capability-asset div {
    position: relative;
  }
  .capability-asset video {
    position: absolute;
    top:2px;
    right: 0;
  }
  .capability .hover-video, .capability .hover-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
  }
  
  .capability .hover-video {
    display: block;
  }
  
  .we-are-not {
    @apply relative flex w-full h-auto;
  }

  .we-are-not h2 {
    @apply text-[32px];
  }

  .we-are-not video {
    @apply w-full h-full absolute object-cover z-0;
  }

  .we-are-not ul {
    @apply list-decimal list-inside;
  }

  .we-are-not li {
    @apply text-[28px] font-semibold dark:text-white font-manrope;
  }

  .brands {
    @apply flex flex-row place-items-center flex-wrap justify-center;
  }

  .brands img {
    @apply w-1/2 lg:w-1/6 h-auto;
  }

  .team {
    @apply flex flex-col gap-3;
  }

  .team h4 {
    @apply dark:text-white font-manrope font-bold;
  }

  .team span {
    color:#999999;
  }

  .contact {
    @apply container mt-[104px] px-8;
  }

  .contact label {
    @apply dark:text-white uppercase text-xl;
  }

  .contact input, select, textarea {
    @apply w-full bg-white rounded p-4 h-[60px] placeholder:text-gray-500 focus:text-black shadow-md;
  }

  .contact textarea {
    @apply h-[120px];
  }

  .contact select {
    @apply invalid:text-gray-500 appearance-none bg-no-repeat;
    background-image: url('assets/images/icon-arrow-down.svg');
    background-position: right 16px center;
  }

  .contact button {
    @apply bg-kollectiff-lime rounded-full text-black w-full lg:w-[180px] text-xl px-8 py-4 flex items-center justify-center;
  }

  .contact form {
    @apply flex flex-col lg:grid grid-cols-1 lg:grid-cols-2 gap-8;
  }

  .contact .city {
    @apply flex flex-col gap-4 items-start;
  }

  .contact .city h3 {
    @apply dark:text-white text-2xl;
  }

  .contact .city .city-content {
    @apply flex flex-col gap-1.5;
  }

  .contact .city .city-contact {
    @apply flex flex-col items-start;
  }

  .contact .city .city-content a, .contact .city .city-content span {
    color: #828282;
  }
  .contact .city .city-contact a:focus-visible{
    color: #BBFF00;
  }

  .form-group {
    @apply flex flex-col gap-1.5 relative;
  }

  .principle {
    @apply flex flex-col gap-1.5;
  }

  .principle h2 {
    @apply text-[28px] text-kollectiff-lime font-semibold font-manrope;
  }

  .principle h5 {
    @apply text-xl dark:text-white font-semibold font-manrope;
  }

  .principle p {
    @apply text-base dark:text-white font-manrope;
  }

  .filter {
    @apply font-monument-grotesk-mono rounded-full py-[9px] px-6 uppercase duration-300;
    color:white;
    border:1px solid white;
  }
  .filter:hover,
  .filter:focus-visible  {
    color:black;
    background-color:#BBFF00;;
    border-color:#BBFF00;
  }

  .filter.active {
    color:black;
    background-color:#BBFF00;;
    border-color:#BBFF00;
  }

  .case-hero {
    @apply mt-[104px] bg-cover bg-center;
    height: calc(100svh - 105px);
  }

  .case-hero h3 {
    @apply dark:text-white font-light text-xl lg:text-[32px];
  }

  .case-hero h2 {
    @apply text-[32px] lg:text-6xl lg:leading-[72px];
  }

  .case-hero .arrow-down:focus-visible {
    border-radius: 50%;
  }
  .arrow-link {
    @apply uppercase font-monument-grotesk-mono text-kollectiff-lime flex gap-2.5;
  }

  .tag {
    @apply text-white text-xs uppercase;
  }

  .tag ~ .tag:not(:empty):before {
    content: ' / ';
  }

  .privacy-policy {
    @apply dark:text-white;
  }

  .privacy-policy p {
    @apply dark:text-white text-base font-normal;
  }

  .privacy-policy span {
    @apply dark:text-white;
  }

  .privacy-policy > div > ul > div > div {
    @apply flex flex-col gap-1.5;
  }

  .privacy-policy > div > ul > div {
    @apply flex flex-col gap-5;
  }
}


.our-values h1 {
  width: 80%;
}

.close-button:hover svg {
  @apply stroke-kollectiff-lime;
}