@tailwind components;

@layer components {
  nav {
    @apply flex items-center border-b dark:border-white min-h-[88px];
  }

  nav ul {
    @apply hidden lg:flex gap-4;
  }

  nav li a {
    @apply font-manrope uppercase block relative dark:text-white hover:text-kollectiff-lime transition-colors duration-300;
    padding: 0.5rem;
  }

  nav li a::after {
    @apply bg-kollectiff-lime absolute bottom-0 left-1/2 opacity-0 h-0.5 w-3 -ml-1.5;
    content: '';
    transition: opacity 300ms, transform 300ms;
  }

  nav li a:hover::after,
  nav li a:focus::after {
    @apply opacity-100;
    transform: translate3d(0, 0.2em, 0);
  }

  .nav-mobile {
    @apply absolute lg:hidden w-full left-0 bottom-0 dark:bg-black;
    height: calc(100dvh - 104px);
  }

  .nav-mobile ul {
    @apply flex flex-col items-center pt-8;
  }

  .nav-home {
    height: calc(100dvh - 88px);
    @apply top-0;
  }

  .nav-section {
    height: calc(100dvh - 104px);
    @apply top-[104px];
  }

  nav a.active {
    @apply text-kollectiff-lime pointer-events-none;
  }

  nav a.active::after {
    @apply opacity-100;
  }

  .sticky {
    @apply fixed top-0 w-full dark:bg-black z-50;
  }

  .menu-btn {
    @apply w-8 h-8 flex items-center justify-center lg:hidden;
  }
  
}