@tailwind components;

@layer components {
  h2 {
    @apply text-2xl lg:text-[40px] font-light dark:text-white leading-tight;
  }

  .prev,
  .next {
    @apply size-8;
    background-image: url('../../assets/images/icon-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .prev {
    @apply rotate-180;
  }
  .prev:hover, .next:hover,.prev:focus-visible, .next:focus-visible {
    background-image: url('../../assets/images/icon-arrow-hover.svg');
    outline: none;
  }
}
