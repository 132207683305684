@tailwind components;

@layer components {
  .work-preview {
    @apply flex flex-col gap-4;
  }

  .work-preview h3, .work-preview h2 {
    @apply dark:text-white font-semibold text-[28px];
  }

  .work-preview p {
    @apply dark:text-white text-xl;
  }

  .work-preview .tag {
    @apply text-[#828282] text-xs uppercase;
  }

  .work-preview .tag ~ .tag:not(:empty):before {
    content: ' / ';
  }

  .work-preview:nth-of-type(5n+1) {
    @apply lg:col-span-7;
  }

  .work-preview:nth-of-type(5n+2) {
    @apply lg:col-span-5;
  }

  .work-preview:nth-of-type(5n+3) {
    @apply lg:col-span-9;
  }

  .work-preview:nth-of-type(5n+4) {
    @apply lg:col-span-5;
  }

  .work-preview:nth-of-type(5n+5) {
    @apply lg:col-span-7;
  }

  .work-preview.works:nth-of-type(6n+1) {
    @apply lg:col-span-12;
  }

  .work-preview.works:nth-of-type(6n+2) {
    @apply lg:col-span-5;
  }

  .work-preview.works:nth-of-type(6n+3) {
    @apply lg:col-span-7;
  }

  .work-preview.works:nth-of-type(6n+4) {
    @apply lg:col-span-9;
  }

  .work-preview.works:nth-of-type(6n+5) {
    @apply lg:col-span-7;
  }

  .work-preview.works:nth-of-type(6n+6) {
    @apply lg:col-span-5;
  }
}