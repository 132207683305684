@tailwind components;

.privacy-body {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.privacy-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.privacy-block h2 {
  font-weight: 600;
  color: #BBFF00;
}
.privacy-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.privacy-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.privacy-policy a {
  color: #BBFF00;
  text-decoration: underline;
}

.privacy-text h3 {
  font-weight: 600;
}
.privacy-content ul {
  margin:0;
  list-style-type: disc;
  padding: 0 0 0 1rem;
}
.privacy-content ul li {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom:0.25rem
}